@value _avatar: 45px;

.component {
  display: flex;
  flex-direction: row;
  color: var(--color-blue-500);
  gap: 0 var(--size-12);

  @supports not (gap: var(--size-12)) {
    & > * {
      margin: 0 var(--size-6);
    }
  }

  & > .avatars {
    flex-shrink: 0;
  }
}

.avatars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > .avatar {
    width: _avatar;
    height: _avatar;

    &:not(:first-child) {
      margin-left: calc(var(--size-12) * -1);
    }
  }
}

.avatar {
  display: block;
  border-radius: 50%;
  background-color: var(--color-white);
  object-fit: cover;
}

.list {
  display: inline;
  padding-top: calc((_avatar - var(--size-24)) * 0.5);

  & > * {
    margin-right: var(--size-6);
  }
}

.item {
  display: inline;

  &:not(:last-child)::after {
    display: inline;
    content: ',';
  }
}
