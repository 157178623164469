@value _heroHeight: 75vh;
@value _heroOverlap: 15vh;

.page {
  & > .sectionHero {
    height: _heroHeight;
  }

  & > .sectionArticles {
    margin-top: calc(_heroOverlap * -1);
    margin-bottom: var(--size-54);

    @media (--viewport-md) {
      margin-bottom: var(--size-120);
    }
  }
}

.grid {
  display: grid;
  gap: var(--size-12);

  @media (--viewport-md) {
    gap: var(--size-18);
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  & > * {
    min-width: 0;
  }

  & > :nth-child(2) {
    @media (--viewport-md) {
      order: 3;
    }
  }

  & > :nth-child(3) {
    @media (--viewport-md) {
      order: 2;
    }
  }

  & > :nth-child(4) {
    @media (--viewport-md) {
      order: 4;
    }
  }
}

.componentHero {
  position: relative;
  z-index: 0;
  background-color: var(--color-red-300);

  & > .heroContainer {
    margin: 0 auto;
    height: 100%;
  }

  & > .heroVisual {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.heroContainer {
  display: flex;
  justify-content: center;
  padding: var(--size-container-padding);
  padding-bottom: _heroOverlap;

  @media (--viewport-md) {
    padding: var(--size-container-padding-md);
    padding-bottom: calc(_heroOverlap + var(--size-48));
  }

  & > .heroLayout {
    width: 100%;
    max-width: var(--size-container-max-width-lg);
    height: 100%;
  }
}

.heroLayout {
  display: grid;
  place-content: center left;

  & > * {
    min-width: 0;
  }

  & > .heroTitle {
    max-width: 850px;
  }
}

.heroTitle {
  font-family: var(--font-family-heading);
  font-weight: var(--font-family-bold);
  font-size: var(--font-size-42);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-82);
  }
}

.componentArticle {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: var(--size-42) var(--size-30);
  background-image: var(--background-image);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  background-position: calc(100% + var(--size-30)) calc(100% + var(--size-30));
  background-size: 280px 280px;
  transition: background var(--duration-md) var(--ease-out-quart);

  @media (--viewport-md) {
    padding: var(--size-42);
    background-position: calc(100% + var(--size-42)) calc(100% + var(--size-42));
    background-size: 400px 400px;
  }

  &:focus-within,
  &:hover {
    background-size: 308px 308px;

    @media (--viewport-md) {
      background-size: 440px 440px;
    }
  }

  &:nth-child(1) {
    @media (--viewport-md) {
      transform: translateY(calc(var(--size-48) * -1));
    }
  }

  &:nth-child(2) {
    @media (--viewport-md) {
      transform: translateY(calc(var(--size-48) * -1));
    }
  }

  & > .link {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > .metaInfo {
    order: -1;
  }

  & > .authors,
  & > .tagList {
    position: relative;
    z-index: 2;
  }

  & > .articleTitle {
    margin-top: var(--size-18);
  }

  & > .summary {
    margin-top: var(--size-24);

    @media (--viewport-md) {
      margin-top: var(--size-6);
    }
  }

  & > .authors {
    flex-grow: 1;
    margin-top: var(--size-24);
  }

  & > .tagList {
    margin-top: var(--size-42);

    @media (--viewport-md) {
      margin-top: var(--size-48);
    }
  }
}

.link {
  font-size: 0;
}

.articleTitle {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-42);
  }
}

.summary {
  @media (--viewport-md) {
    font-size: var(--font-size-20);
  }
}
