.component {
  background: var(--color-blue-300);
  padding: var(--size-36) 0;

  @media (--viewport-md) {
    padding: var(--size-120) 0;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    margin-bottom: var(--size-24);
    max-width: 500px;
  }

  & > .envelopIconLayout {
    width: 120px;

    @media (--viewport-md) {
      width: 160px;
    }

    @media (--viewport-lg) {
      width: 200px;
    }
  }
}

.content {
  display: grid;
  grid-template-areas: 'content';

  & > .openFormButton {
    grid-area: content;
    justify-self: start;
  }

  & > .formContainer {
    grid-area: content;
  }
}

.formContainer {
  overflow: hidden;
}

.form {
  & > .formGrid {
    margin-bottom: var(--size-24);
  }
}

.formGrid {
  position: relative;
  z-index: 0;
  display: grid;
  align-content: start;
  row-gap: var(--size-18);
  column-gap: var(--size-18);
  grid-template-areas:
    'firstname'
    'lastname'
    'email'
    'confirm';

  @media (--viewport-sm) {
    grid-template-areas:
      'firstname  lastname'
      'email      email'
      'confirm    confirm';
  }

  @media (--viewport-md) {
    row-gap: var(--size-24);
    grid-template-areas:
      'firstname  lastname'
      'email      email '
      'confirm    confirm';
  }

  & > * {
    min-width: 0;
  }

  & > .firstNameLayout {
    grid-area: firstname;
  }

  & > .lastNameLayout {
    grid-area: lastname;
  }

  & > .emailLayout {
    grid-area: email;
  }

  & > .confirmLayout {
    grid-area: confirm;
  }
}
