.layout {
  padding: var(--size-54) 0;

  @media (--viewport-md) {
    padding: var(--size-96) 0;
  }

  & > .overviewTitle {
    margin-bottom: var(--size-36);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .pagination {
    margin-top: var(--size-36);

    @media (--viewport-md) {
      margin-top: var(--size-48);
    }
  }
}

.grid {
  display: grid;
  gap: var(--size-12);

  @media (--viewport-md) {
    gap: var(--size-24);
  }

  & > * {
    min-width: 0;
  }
}
