@value _heroVisualHeight: 65vh;

.page {
  & > .sectionContent {
    margin-top: var(--size-36);
    margin-bottom: var(--size-96);

    @media (--viewport-md) {
      margin-top: var(--size-72);
      margin-bottom: var(--size-120);
    }
  }

  & > .sectionIntroduction {
    margin-top: var(--size-36);
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-top: var(--size-60);
      margin-bottom: var(--size-48);
    }
  }
}

.sectionHero {
  & > .heroVisual {
    min-height: 420px;
    height: _heroVisualHeight;
  }
}

.componentHeroVisual {
  position: relative;
  z-index: 0;

  & > .heroVisualContainer {
    margin: 0 auto;
    height: 100%;
  }

  & > .heroVisualImage {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.heroVisualContainer {
  display: flex;
  justify-content: center;
  padding: var(--size-container-padding);

  @media (--viewport-md) {
    padding: var(--size-container-padding-md);
  }

  & > .heroVisualLayout {
    width: 100%;
    max-width: var(--size-container-max-width-md);
    height: 100%;
  }
}

.heroVisualLayout {
  display: grid;
  place-content: center left;

  & > * {
    min-width: 0;
  }

  & > .heroVisualTitle {
    max-width: 680px;
  }
}

.heroVisualTitle {
  font-family: var(--font-family-heading);
  font-weight: var(--font-family-bold);
  font-size: var(--font-size-42);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-82);
  }
}

.heroPlainTitle {
  padding-top: var(--size-36);

  @media (--viewport-md) {
    padding-top: var(--size-72);
  }
}

.sectionIntroduction {
  font-size: var(--font-size-20);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}

.sectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-container-padding);

  @media (--viewport-md) {
    padding: var(--size-container-padding-md);
  }

  & > * {
    width: 100%;
    max-width: var(--size-container-max-width-md);
  }
}
