.component {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > .next {
    order: 2;
    flex: 0 0 auto;
    margin-left: 10px;
  }

  & > .previous {
    order: 1;
    flex: 0 0 auto;
    margin-right: 10px;

    @media (--viewport-sm) {
      order: 0;
    }
  }

  & > .bullets {
    margin-bottom: var(--size-18);
    width: 100%;

    @media (--viewport-sm) {
      margin-bottom: 0;
      width: auto;
    }
  }
}

.next,
.previous {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 11px 15px;
  border: 1px solid currentColor;

  &:not(.disabled) {
    color: var(--color-blue-500);
    transition: color var(--duration-sm) var(--ease-out-quart), background-color var(--duration-sm) var(--ease-out-quart);

    &:focus,
    &:hover {
      color: var(--color-white);
      background-color: var(--color-blue-500);
      border-color: var(--color-blue-500);
    }
  }

  &[aria-hidden='true'] {
    opacity: 0;
  }
}

.bullets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.hellip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px var(--size-18);
}

.componentBullet {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px var(--size-18);
  overflow: hidden;
  color: var(--color-blue-500);
  font-size: var(--font-size-14);

  &::after {
    content: attr(data-page);
  }

  & > .screenreaderText {
    position: absolute;
    right: 100%;
  }

  & > .indicator {
    position: absolute;
    bottom: 4px;
    width: 20px;
    height: 3px;
  }

  &.isActive {
    color: var(--color-black);
    font-weight: var(--font-weight-semibold);
  }
}

.indicator {
  opacity: 0;

  &.isActive {
    opacity: 1;
  }
}

.componentArrow {
  position: relative;

  &.disabled {
    pointer-events: none;
    color: var(--color-gray-300);
  }
}
