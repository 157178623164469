.layout {
  padding: var(--size-30) 0;

  @media (--viewport-md) {
    padding: var(--size-60) 0;
  }

  & > .title {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-42);
    }
  }
}
