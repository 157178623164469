.component {
  position: relative;
  z-index: 0;

  & > .syntaxHighlighter {
    margin: 0 !important; /* overruling react-syntax-highlighter styling */
    min-height: calc(var(--size-42) + var(--size-24) * 2);
  }

  & > .copyButton {
    position: absolute;
    z-index: 1;
    bottom: var(--size-24);
    right: var(--size-24);
    width: var(--size-42);
    height: var(--size-42);
  }
}

.syntaxHighlighter {
  padding: var(--size-24) !important; /* overruling react-syntax-highlighter styling */

  @media (--viewport-md) {
    padding: var(--size-48) !important; /* overruling react-syntax-highlighter styling */
  }
}

.copyButton {
  &.isCopied {
    animation: copied var(--duration-md);
  }
}

@keyframes copied {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
