.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-24);
  }

  & > .imageInline:not(:last-child) {
    @media (--viewport-md) {
      margin-right: var(--size-24);
    }
  }

  & > .cookiePermissions {
    margin-top: var(--size-32);
    margin-bottom: var(--size-32);
  }
}
