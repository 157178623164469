.component {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--size-12);
  color: var(--color-blue-700);

  & > :not(:last-child) {
    @supports not (gap: 0 var(--size-12)) {
      margin-right: var(--size-12);
    }
  }
}

.category {
  &::before {
    display: inline-block;
    content: '•';
    position: relative;
    margin-right: var(--size-12);
  }
}
