.component {
  color: var(--color-white);
  background-color: var(--color-blue-700);
}

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--size-42);
  padding-bottom: var(--size-42);
  font-size: var(--font-size-14);

  @media (--viewport-md) {
    padding-top: var(--size-54);
    padding-bottom: var(--size-24);
  }
}

.links,
.socials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 var(--size-24);

  & > * {
    @supports not (gap: 0 var(--size-24)) {
      margin: 0 var(--size-12);
    }
  }
}

.socials {
  padding-bottom: var(--size-54);

  @media (--viewport-md) {
    padding-bottom: var(--size-84);
  }
}

.links {
  padding-top: var(--size-24);
  white-space: nowrap;

  @media (--viewport-md) {
    padding-top: var(--size-30);
  }
}

.icon {
  & > * {
    width: 33px;
    height: 33px;
  }
}

.logo {
  display: block;
}
