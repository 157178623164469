.component {
  & > .audio {
    height: 0;
  }

  & > .controls {
    height: var(--size-60);
  }
}

.audio {
  opacity: 0;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--size-42);
  background-color: var(--color-blue-700);
  padding: 0 var(--size-30) 0 10px;

  & > .button {
    width: var(--size-42);
    height: var(--size-42);
  }

  & > .scrobbler {
    flex-grow: 1;
    margin-right: var(--size-12);
    margin-left: var(--size-12);
    height: var(--size-12);
  }
}

.button {
  display: grid;
  place-content: center;
  color: var(--color-blue-700);
  background-color: var(--color-white);
  border-radius: 50%;

  & > * {
    min-width: 0;
  }
}

.componentScrobbler {
  position: relative;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: var(--size-6);
  overflow: hidden;

  & > .scrobblerInput,
  & > .scrobblerProgress {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .scrobblerInput {
    z-index: 0;
    top: 0;
  }

  & > .scrobblerProgress {
    z-index: 1;
    width: 100%;
    height: var(--size-12);
  }
}

.scrobblerInput {
  opacity: 0;
}

.scrobblerProgress {
  background-color: var(--color-white);
  pointer-events: none;
  transform-origin: left;
}

.time {
  font-variant-numeric: tabular-nums;
  color: var(--color-white);
}
