.component {
  & > .text {
    margin-bottom: var(--size-30);
  }
}

.imageAndText {
  display: flex;
  gap: var(--size-18);

  & > .image {
    width: var(--size-78);
    height: var(--size-78);
  }
}

.text {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-42);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-72);
  }
}

.image {
  overflow: hidden;
  border-radius: 50%;
}

.nameAndRole {
  display: grid;
  place-content: center left;
}

.name {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-24);
  line-height: var(--line-height-narrow);
  color: var(--color-blue-700);
}

.role {
  font-size: var(--font-size-16);
}
