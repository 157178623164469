.component {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }

  & > .input {
    width: 100%;
    height: var(--size-input-height);
  }

  & > .button {
    position: absolute;
    right: 16px;
  }
}

.input {
  position: relative;
  background-color: var(--color-white);
  border-radius: var(--size-48);
  color: var(--color-black);
  font-size: var(--font-size-18);
  line-height: 1;
  padding: var(--size-10) var(--size-48) var(--size-10) var(--size-24);
  outline: none;

  &::placeholder {
    color: var(--color-gray-300);
  }
}

.icon {
  color: var(--color-blue-700);
}
