.componentBase {
  display: flex;
  flex-direction: column;
  position: relative;

  & > .input {
    height: var(--size-input-height);
  }

  & > .error {
    position: absolute;
    top: 100%;
  }
}

.label {
  font-size: var(--font-size-18);
}

.input {
  color: var(--color-black);
  background-color: var(--color-white);
  font-size: var(--font-size-20);
  line-height: 1;
  padding: 0 var(--size-10);
  outline: none;
}

.error {
  color: red;
}

.componentCheckboxWithLink {
  display: flex;
  flex-direction: column;
}

.checkboxAndLabel {
  display: flex;
  align-items: center;
  gap: var(--size-10);

  @supports not (gap: var(--size-10)) {
    & > * {
      margin: 0 var(--size-6);
    }
  }

  & > .checkbox {
    order: 1;
    width: var(--size-18);
    height: var(--size-18);
  }

  & > .labelCheckbox {
    order: 2;
  }
}

.checkbox {
  position: relative;
  background-color: var(--color-white);
  cursor: pointer;
  border-radius: var(--size-6);
  appearance: checkbox;
}

.labelCheckbox {
  font-size: var(--font-size-14);
  cursor: pointer;
}

.link {
  color: var(--color-blue-700);
  text-decoration: underline;
}
