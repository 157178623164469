@value _avatar: 149px;

.component {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  color: var(--color-blue-100);

  @media (--viewport-md) {
    flex-direction: row-reverse;
  }

  & > .avatar {
    margin-bottom: var(--size-18);
    width: _avatar;
    height: _avatar;
    flex-shrink: 0;
    align-self: center;

    @media (--viewport-md) {
      align-self: flex-start;
      margin-right: var(--size-30);
      margin-bottom: 0;
    }
  }

  & > .content {
    flex-grow: 1;
  }
}

.avatar {
  border-radius: 50%;
  background-color: var(--color-white);
  overflow: hidden;

  & > * {
    width: 100%;
    height: 100%;
  }
}

.avatarImage {
  object-fit: cover;
}

.content {
  @media (--viewport-md) {
    padding-top: var(--size-30);
  }

  & > .about {
    margin-top: var(--size-12);
  }

  & > .link {
    margin-top: var(--size-12);
  }
}

.name {
  color: var(--color-blue-100);
  font-size: var(--font-size-24);
  line-height: var(--line-height-narrow);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.about {
  color: var(--color-blue-100);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.link {
  display: flex;
  flex-direction: row;
  color: var(--color-blue-100);

  & > .icon {
    margin-right: var(--size-6);
    width: var(--size-24);
    height: var(--size-24);
  }
}
