
.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: var(--size-42) var(--size-30);
  background-color: var(--color-white);
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-position: calc(100% + 280px) calc(100% + 280px);
  background-size: 280px 280px;
  transition: background var(--duration-md) var(--ease-out-quart);

  &:focus-within,
  &:hover {
    background-color: var(--background-color);
    background-position: calc(100% + var(--size-30)) calc(100% + var(--size-30));

    @media (--viewport-md) {
      background-position: calc(100% + var(--size-42)) calc(100% + var(--size-42));
    }
  }

  @media (--viewport-md) {
    padding: var(--size-42);
  }

  & > .link {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & > .metaInfo {
    order: -1;
  }

  & > .authors,
  & > .tagList {
    position: relative;
    z-index: 2;
  }

  & > .title {
    margin-top: var(--size-18);
  }

  & > .summary {
    margin-top: var(--size-24);

    @media (--viewport-md) {
      margin-top: var(--size-6);
    }
  }

  & > .authors {
    margin-top: var(--size-24);
  }

  & > .tagList {
    margin-top: var(--size-42);

    @media (--viewport-md) {
      margin-top: var(--size-48);
    }
  }
}

.link {
  font-size: 0;
}

.title {
  font-size: var(--font-size-32);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-42);
  }
}

.summary {
  @media (--viewport-md) {
    font-size: var(--font-size-20);
  }
}
