.component_root {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  color: var(--color-white);
  background-color: var(--color-blue-700);
  transition: transform var(--duration-sm) var(--ease-in-out);

  &.isHidden {
    transform: translateY(-100%);
  }
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-site-header-height-sm) !important;

  @media (--viewport-lg) {
    height: var(--size-site-header-height-md) !important;
  }

  & > .logo {
    max-width: 80%;

    @media (--viewport-lg) {
      max-width: 100%;
    }
  }

  & > .menuDesktop {
    height: 100%;
  }
}

.listDesktop {
  display: flex;
  border-right: 1px solid var(--color-blue-400);

  & > * {
    flex-shrink: 0;

    &:last-child {
      margin-right: var(--size-24);
    }

    &:not(:last-child) {
      margin-right: var(--size-30);
    }
  }
}

.componentDesktopSearch {
  position: relative;
  display: flex;

  & > .searchContainer {
    position: absolute;
    top: calc(100% + 37px);
    right: 0;
    width: 409px;
  }
}

.searchContainer {
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
  transition: var(--ease-in-out) var(--duration-sm);
  transition-property: transform, opacity;
  will-change: transform, opacity;

  &.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}

.drawerBody {
  transition: height var(--duration-md) var(--ease-out-quart);
}

.listMobile {
  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.itemDesktop {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;

  & > .hoverMenu {
    width: 210px;
    position: absolute;
    top: var(--size-site-header-height-sm);
    z-index: -1;
    display: none;
  }

  &.isActive,
  &:focus,
  &:hover {
    & > .hoverMenu {
      display: block; /* stylelint-disable-line kaliber/layout-related-properties */
    }
  }
}

.hoverMenu {
  padding: var(--size-30) var(--size-18) var(--size-18);
  background-color: var(--color-blue-700);

  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

.linkWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  transition: transform var(--duration-sm) var(--ease-out-quart);
  transform: translateX(calc(var(--size-24) * -1));

  &.isActive,
  &:focus,
  &:hover {
    transform: translateX(0);

    & > .icon {
      transform: translateX(0); /* stylelint-disable-line kaliber/layout-related-properties */
    }
  }

  & > .icon {
    margin-right: var(--size-6);
  }
}

.itemMobile {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: var(--font-size-22);

  &.relativeToParent {
    position: static;
  }

  & > .overlay {
    position: absolute;
    top: var(--size-site-header-height-sm);
    right: 0;
    bottom: 0;
    left: 5vw;
  }
}

.subitem {
  overflow: hidden;
}

.componentMenuDesktop {
  display: none;

  @media (--viewport-lg) {
    display: flex;
  }

  & > .listDesktop {
    margin-right: var(--size-24);
  }
}

.componentMenuMobile {
  display: flex;

  @media (--viewport-lg) {
    display: none;
  }

  & > .hamburger {
    width: var(--size-42);
    height: var(--size-42);
  }
}

.componentDrawer {
  position: relative;
  z-index: 0;

  & > .drawerInner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    max-width: 500px;
    overflow: hidden;
  }
}

.drawerInner {
  background-color: var(--color-blue-700);
  line-height: var(--line-height-narrow);
  transform: translateX(100vw);
  opacity: 0;
  transition: transform var(--duration-md) var(--ease-out-quart), opacity var(--duration-md) var(--ease-out-quart);

  &.isActive {
    transform: none;
    opacity: 1;
  }
}

.drawerLayout {
  & > .searchForm {
    margin: var(--size-30) 0;
  }

  & > .drawerBody {
    margin: var(--size-30) 0;
  }
}

.componentDrawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--size-site-header-height-sm) !important;

  @media (--viewport--md) {
    height: var(--size-site-header-height-md) !important;
  }

  & > .logo {
    max-width: 80%;

    @media (--viewport-lg) {
      max-width: 100%;
    }
  }

  & > .close {
    width: var(--size-42);
    height: var(--size-42);
  }
}

.overlay {
  background-color: var(--color-blue-700);
  line-height: var(--line-height-narrow);
  transform: translateX(-100%);
  opacity: 0;
  transition: transform var(--duration-md) var(--ease-out-quart), opacity var(--duration-md) var(--ease-out-quart);

  &.isActive {
    transform: none;
    opacity: 1;
  }
}

.overlayContent {
  & > .backButton {
    margin: var(--size-30) 0;
  }
}

.close {
  transition: transform var(--duration-sm) var(--ease-out-quart);

  &:focus,
  &:hover {
    transform: rotate(90deg);
  }
}

.closeButton {
  padding: var(--size-12);
  color: var(--color-blue-700);
}

.backButton {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-base);
}

.component_rootSearchOverlay {
  background-color: #0a0a0a;
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;

  &.open {
    opacity: 0.5;
    pointer-events: auto;
  }
}
