.page {
  & > .section {
    height: calc(100vh + var(--size-site-header-height-md)); /* fix for always showing blue blackground on scroll */
    margin-top: calc(-1 * var(--size-site-header-height-md)); /* fix for always showing blue blackground on scroll */
  }
}

.section {
  background-color: var(--color-blue-700);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: var(--size-site-header-height-md); /* fix for always showing blue blackground on scroll */
}

.content {
  & > * {
    margin-bottom: var(--size-42);
  }
}

.title {
  color: var(--color-white);
}

.text {
  color: var(--color-white);

  @media (--viewport-md) {
    font-size: var(--font-size-18);
  }
}
