.component {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  padding: 0 var(--size-120);
  gap: var(--size-12);

  & > .link {
    min-height: 50px;

    @supports not (gap: var(--size-12)) {
      margin: var(--size-6);
    }
  }
}

.componentLink {
  padding: 0 var(--size-24);
  font-size: var(--font-size-16);
  line-height: 50px;
  background-color: white;
  border-radius: var(--size-24);
  display: flex;
  align-items: center;
  overflow: hidden;

  & > .logo {
    max-width: 120px;
    max-height: var(--size-30);
  }
}

.logo {
  & > svg {
    max-height: var(--size-30);
  }
}
