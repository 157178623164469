.page {
  padding-top: var(--size-42);

  @media (--viewport-md) {
    padding-top: var(--size-72);
  }

  & > .authors {
    margin-top: var(--size-96);

    @media (--viewport-md) {
      margin-top: var(--size-120);
    }
  }

  & > .sectionIntroduction {
    margin-bottom: var(--size-24);

    @media (--viewport-md) {
      margin-bottom: var(--size-48);
    }
  }

  & > .sectionContent {
    margin-bottom: var(--size-96);

    @media (--viewport-md) {
      margin-bottom: var(--size-120);
    }
  }
}

.sectionPodcastHeading {
  padding-bottom: var(--size-48);

  @media (--viewport-md) {
    padding-bottom: var(--size-60);
  }
}

.sectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5vw;

  @media (--viewport-md) {
    padding: 0 calc(2.5vw + var(--size-24));
  }

  & > * {
    width: 100%;
    max-width: var(--size-container-max-width-md);
  }

  & > :not(:first-child) {
    margin-top: var(--size-6);
  }
}

.sectionIntroduction,
.sectionTagList {
  &:not(:first-child) {
    padding-top: var(--size-36);

    @media (--viewport-md) {
      padding-top: var(--size-60);
    }
  }
}

.sectionIntroduction {
  font-size: var(--font-size-20);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-28);
  }
}

.sectionPlatformList {
  padding-top: var(--size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.platformListLayout {
  text-align: center;

  & > .platformHeading {
    margin-bottom: var(--size-12);
  }
}

.platformHeading {
  display: block;
  color: var(--color-blue-700);
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.podcastHeadingLayout {
  display: grid;
  gap: var(--size-12) 0;

  @media (--viewport-md) {
    gap: var(--size-24) 0;
  }

  & > * {
    min-width: 0;
  }
}

.tagListLayout {
  display: grid;
  gap: var(--size-12) 0;

  & > * {
    min-width: 0;
  }
}

.authors {
  background-color: var(--color-blue-700);
}

.authorInfo {
  padding-top: var(--size-42);
  padding-bottom: var(--size-42);

  @media (--viewport-md) {
    padding-top: var(--size-72);
    padding-bottom: var(--size-72);
  }

  &:not(:first-child) {
    box-shadow: inset 0 1px 1px var(--color-blue-400);
  }
}
