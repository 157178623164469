.page {
  padding-top: var(--size-54);
  padding-bottom: var(--size-54);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-green-100);

  @media (--viewport-md) {
    padding-top: var(--size-96);
    padding-bottom: var(--size-96);
  }
}

.layout {
  position: relative;
  z-index: 0;

  & > .copy {
    max-width: 90vw;

    @media (--viewport-md) {
      width: 50vw;
      max-width: 820px;
    }
  }

  & > .visual {
    position: relative;
    z-index: -1;
    margin-top: calc(var(--size-120) * -1);

    @media (--viewport-md) {
      position: absolute;
      top: 50%;
      left: 300px;
      width: 100vw;
      max-width: 1296px;
      margin-top: 0;
    }
  }
}

.copy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: var(--size-24);
  }
}

.visual {
  transform: translateX(calc(-200px + 10vw));

  @media (--viewport-md) {
    transform: translateY(-50%);
  }

  & > * {
    min-width: 800px;
  }
}
