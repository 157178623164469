.component,
.componentSingleIcon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--color-blue-500);
}

.component {
  gap: var(--size-12);

  & > .link {
    @supports not (gap: var(--size-12)) {
      margin-top: var(--size-6);
      margin-right: var(--size-12);
      margin-bottom: var(--size-6);
    }
  }
}

.componentSingleIcon {
  position: relative;
  padding-left: var(--size-30);

  & > .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > .link {
    margin-right: var(--size-6);
  }
}

.link {
  display: inline-flex;
  justify-content: center;

  & > .icon {
    margin-right: var(--size-6);
    height: var(--size-24);
  }
}

.linkComma:not(:last-child)::after {
  display: inline;
  content: ',';
}

.icon {
  display: inline-block;
}
