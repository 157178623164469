.componentMainContent {
  & > :not(:first-child) {
    margin-top: var(--size-24);

    &.heading {
      margin-top: var(--size-72);

      @media (--viewport-md) {
        margin-top: var(--size-108);
      }
    }

    &.subheading {
      margin-top: var(--size-30);

      @media (--viewport-md) {
        margin-top: var(--size-60);
      }
    }
  }

  /* stylelint-disable kaliber/selector-policy */
  /* reason: dealing with WYSIWYG content */
  & > .paragraph + .list,
  & > .list + .list,
  & > .heading + .subheading,
  & > .heading + *,
  & > .subheading + * {
    margin-top: var(--size-6);
  }
  /* stylelint-enable kaliber/selector-policy */
}

.paragraph,
.list,
.blockquote {
  font-size: var(--font-size-16);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.blockquote {
  padding-right: var(--size-24);
  padding-left: var(--size-24);
  color: var(--color-blue-700);
  border-left: 4px solid var(--color-blue-700);

  &::before,
  &::after {
    position: relative;
    display: inline;
  }

  &::before {
    content: '\201C';
  }

  &::after {
    content: '\201D';
  }
}

.subheading {
  font-size: var(--font-size-24);

  @media (--viewport-md) {
    font-size: var(--font-size-32);
  }
}

.list {
  padding-left: var(--size-24);
  list-style-position: outside;

  @media (--viewport-md) {
    padding-left: var(--size-42);
  }

  & > .listItem {
    @media (--viewport-md) {
      /* stylelint-disable kaliber/layout-related-properties */
      /* reason: spacing can not be set with margin, it's value is depended on the context. */
      padding-left: var(--size-18);
      /* stylelint-enable kaliber/layout-related-properties */
    }
  }
}

.listOrdered {
  list-style-type: decimal;

  & > .listItem {
    /* stylelint-disable kaliber/layout-related-properties */
    /* reason: spacing can not be set with margin, it's value is depended on the .listOrdered context. */
    padding-left: var(--size-6);
    /* stylelint-enable kaliber/layout-related-properties */
  }
}

.listUnordered {
  list-style-type: disc;
}

::marker {
  color: var(--color-blue-700);
  font-weight: var(--font-weight-bold);
}
