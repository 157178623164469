.component {
  position: relative;
  word-wrap: break-word;

  & > .heading {
    margin-right: var(--size-36);

    @media (--viewport-md) {
      margin-right: var(--size-60);
    }
  }

  & > .badge {
    position: absolute;
    top: -22px;
    width: var(--size-36);
    height: var(--size-36);

    @media (--viewport-md) {
      top: -36px;
      width: var(--size-60);
      height: var(--size-60);
    }
  }
}

.heading {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-42);
  line-height: var(--line-height-heading);
  color: var(--color-blue-700);

  @media (--viewport-md) {
    font-size: var(--font-size-82);
  }
}

.badge {
  opacity: 0;
  display: grid;
  place-content: center;
  font-size: var(--font-size-16);
  color: var(--color-blue-100);
  border-radius: 50%;
  background-color: var(--color-blue-700);
  transition: opacity var(--duration-sm) var(--ease-in-out);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }

  & > * {
    min-width: 0;
  }

  &.isProcessed {
    opacity: 1;
  }
}
